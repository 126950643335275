
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IChat, ITappableArea } from '@/utils/types'
import TappableItem from '@/components/scenarios/preview/TappableItem.vue'

@Options({
  components: { TappableItem },
  directives: { maska },
  emits: ['update:handleAnswerClick'],
})
export default class Imagemap extends Vue {
  @Prop()
  msg!: IChat

  get imagemapAnswer() {
    return this.msg.imagemap_answer
  }

  get getDefaultSize() {
    if (this.imagemapAnswer && this.imagemapAnswer.image_url_sizes && this.imagemapAnswer.image_url_sizes) {
      return this.imagemapAnswer.image_url_sizes.find((item) => item.width === 240)
    }

    return undefined
  }

  get backgroundImage() {
    if (this.getDefaultSize && this.getDefaultSize.image_url) {
      return `url(${this.getDefaultSize.image_url})`
    } else {
      return ''
    }
  }

  get backgroundSize() {
    if (this.getDefaultSize && this.getDefaultSize.image_url) {
      return `${this.getDefaultSize.width}px ${this.getDefaultSize.height}px`
    } else {
      return ''
    }
  }

  convertArea(area700: ITappableArea) {
    const area240: ITappableArea = cloneDeep(area700)
    area240.x = Math.round(((area700.x ?? 0) * 240) / 700)
    area240.y = Math.round(((area700.y ?? 0) * 240) / 700)
    area240.width = Math.round(((area700.width ?? 0) * 240) / 700)
    area240.height = Math.round(((area700.height ?? 0) * 240) / 700)
    return area240
  }

  handleAnswerClick(answerId: string, title: string) {
    this.$emit('update:handleAnswerClick', this.msg.uniqueId, answerId, title)
  }
}
