
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import ButtonEmojiSelector from './ButtonEmojiSelector.vue'

@Options({
  components: {
    ButtonEmojiSelector,
  },
  directives: { maska },
})
export default class InputEmojiSelector extends Vue {
  @Prop()
  modelValue!: string

  @Prop()
  placeholder!: string

  @Prop()
  type!: string

  @Prop()
  maxlength!: string

  @Prop()
  hint!: string

  @Prop()
  rules!: string[]

  get value() {
    return this.modelValue
  }

  set value(value) {
    this.$emit('update:modelValue', value)
  }

  showErrorMessage() {
    this.$refs.inputLt.focus()
    this.$refs.inputLt.blur()
  }
}
