
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IChat } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:handleAnswerClick'],
})
export default class AnswerImageColumn extends Vue {
  @Prop()
  msg!: IChat

  get images() {
    return this.msg.image_answers
  }

  handleAnswerClick(answerId: string, title: string) {
    this.$emit('update:handleAnswerClick', this.msg.uniqueId, answerId, title)
  }

  mounted() {
    const slider = document.querySelector('.list-x') as HTMLElement
    let isDown = false
    let startX
    let scrollLeft
    if (slider) {
      slider.addEventListener('mousedown', (e) => {
        isDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3
        slider.scrollLeft = scrollLeft - walk
        console.log(walk)
      })
    }
  }
}
