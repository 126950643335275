<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>{{ $t('label.edit_image') }}</q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <cropper
              ref="cropper"
              :src="img"
              class="cropper"
              :default-size="defaultSize"
              :stencil-props="{ aspectRatio: aspectRatio }"
              image-restriction="none"
              @change="change"
              :canvas="{ minHeight: 0, minWidth: 0, maxHeight: stencilHeight, maxWidth: stencilWidth }"
              :resize-image="false"
              :move-image="false"
            />
          </div>
          <q-space class="q-my-md" />
          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

@Options({
  components: { Cropper },
  directives: { maska },
  emits: ['update:closeModal', 'update:onCropImage'],
})
export default class CropperImageSelector extends Vue {
  @Prop()
  file!: File

  @Prop()
  modalVisible!: boolean

  @Prop()
  aspectRatio!: number

  @Prop({ default: false })
  isStatic!: boolean

  @Prop({ default: 1040 })
  stencilWidth!: number

  @Prop({ default: 1040 })
  stencilHeight!: number

  get img() {
    return URL.createObjectURL(this.file)
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  change({ coordinates, canvas }) {
    console.log(coordinates, canvas)
  }

  defaultSize({ imageSize, visibleArea }) {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    }
  }

  onCancel() {
    this.visible = false
  }

  onSubmit() {
    const { coordinates, canvas } = this.$refs.cropper.getResult()
    console.log(coordinates, canvas)
    if (canvas) {
      if (this.isStatic) {
        const scaledCanvas: HTMLCanvasElement = document.createElement('canvas')
        scaledCanvas.width = this.stencilWidth
        scaledCanvas.height = this.stencilHeight
        const scaledContext: CanvasRenderingContext2D | null = scaledCanvas.getContext('2d')
        if (scaledContext) {
          scaledContext.drawImage(canvas, 0, 0, this.stencilWidth, this.stencilHeight)
          scaledCanvas.toBlob((blob: Blob | null) => {
            if (blob) {
              const newFile: File = new File([blob], this.file.name, { type: this.file.type })
              this.$emit('update:onCropImage', newFile, this.stencilWidth, this.stencilWidth)
            }
          }, this.file.type)
        }
      } else {
        canvas.toBlob((blob) => {
          const newFile = new File([blob], this.file.name, { type: this.file.type })
          this.$emit('update:onCropImage', newFile, coordinates.width, coordinates.height)
        }, this.file.type)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cropper {
  height: calc(100vh - 250px);
  background: #ddd;
}

// :deep() {
//   .vue-advanced-cropper__foreground {
//     background: #ffffff !important;
//     // opacity: 100%;
//   }
//   .vue-simple-handler {
//     background-color: black;
//   }
// }
</style>
