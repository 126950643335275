<template>
  <div>
    <div class="items-center full-width q-gutter-xs preview-buttons" :class="listImagesId">
      <template v-if="formField && fieldIndex > 0">
        <q-btn rounded color="primary" size="sm" @click="backField" no-caps class="full-width">
          <div class="q-pl-xs">{{ $t('label.common.form.back_to_last_one') }}</div>
        </q-btn>
      </template>
      <template v-if="resource && hasAnswers(resource) && resource.answers && resource.answers.type === 'text'">
        <template v-for="(answer, key) in answers" :key="key">
          <q-btn
            rounded
            color="primary"
            size="sm"
            @click="onAnswerClick(nextUniqueId, answer._id, answer.title)"
            v-if="answer._id"
            no-caps
            class="full-width"
          >
            <q-avatar size="xs" v-if="answer.image_url">
              <img :src="answer.image_url" />
            </q-avatar>
            <div class="q-pl-xs">{{ answer.title }}</div></q-btn
          >
        </template>
      </template>
    </div>
    <SendMessageSelector
      v-model="modelValue"
      :answerType="answerType"
      :nextUniqueId="nextUniqueId"
      :formField="formField"
      style="z-index: 2"
      @update:handleFreeTextAnswer="onAnswerClick"
    ></SendMessageSelector>
  </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { EScenarioResourceType, IChat, IGoal, IQuestion, IForm, IFormDetail } from '@/utils/types'
import { ICard } from 'bot-flow-maker/src/types'
import Imagemap from '@/components/scenarios/preview/Imagemap.vue'
import SendMessageSelector from '@/components/scenarios/preview/SendMessageSelector.vue'
import { constant } from '@/utils/constants'

@Options({
  components: { Imagemap, SendMessageSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:handleAnswerClick', 'update:backField'],
})
export default class QuickReplyButton extends Vue {
  @Prop()
  modelValue!: IChat[]

  @Prop()
  lastCard!: ICard

  @Prop()
  nextUniqueId!: string

  @Prop()
  formField!: IFormDetail

  @Prop()
  fieldIndex!: number

  listImagesId = 'list-x-' + Math.floor(Math.random() * 100)

  get value() {
    return this.modelValue
  }

  set value(value: IChat[]) {
    this.$emit('update:modelValue', value)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  get resource() {
    return this.getResourceByResourceType(this.lastCard.id, this.lastCard.cardType)
  }

  get answerType() {
    if (this.hasAnswers(this.resource)) {
      if (this.resource && this.resource.answers) {
        return this.resource.answers.type
      }
    } else {
      if (this.formField) {
        return constant.ANSWER_TYPE.FREETEXT
      }
    }

    return ''
  }

  get answers() {
    if (!this.resource) {
      return undefined
    }
    if (this.hasAnswers(this.resource)) {
      if (this.resource && this.resource.answers && this.resource.answers.type === 'text') {
        return this.resource.answers.text_answers
      } else if (this.resource && this.resource.answers && this.resource.answers.type === 'imagemap') {
        return this.resource.answers.imagemap_answer
      }
    }

    return undefined
  }

  onAnswerClick(uniqueId: string, answerId: string, title: string) {
    this.$emit('update:handleAnswerClick', uniqueId, answerId, title)
  }

  getResourceByResourceType(resource_id, resourceType) {
    let item: IQuestion | IGoal | IForm = {}
    if (resourceType === EScenarioResourceType.question) {
      item = this.questions.find((question) => question._id === resource_id)
    } else if (resourceType === EScenarioResourceType.goal) {
      item = this.goals.find((goal) => goal._id === resource_id)
    } else if (resourceType === EScenarioResourceType.form) {
      item = this.forms.find((form) => form._id === resource_id)
    }

    return item
  }

  hasAnswers(resource: IQuestion | IGoal | IForm): resource is IQuestion | IGoal {
    return (resource as IQuestion | IGoal).answers !== undefined
  }

  backField() {
    this.$emit('update:backField')
  }

  mounted() {
    const slider = document.querySelector('.' + this.listImagesId) as HTMLElement
    let isDown = false
    let startX
    let scrollLeft
    if (slider) {
      slider.addEventListener('mousedown', (e) => {
        isDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3
        slider.scrollLeft = scrollLeft - walk
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

.preview-buttons {
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 1;
  text-align: center;
  white-space: nowrap;
  will-change: transform;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
}
</style>
