
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { EScenarioResourceType, IAnswer, IChat, IGoal, IMessage, IQuestion, IForm, IFormDetail } from '@/utils/types'
import { ICard } from 'bot-flow-maker/src/types'
import { constant, FORM_FIELD_TYPE, FREETEXT_OPTION_TYPE } from '@/utils/constants'
import { UserModel } from '@/models/user-model'
import Imagemap from '@/components/scenarios/preview/Imagemap.vue'
import ImageCarouselColumn from '@/components/scenarios/preview/ImageCarouselColumn.vue'
import AnswerImageColumn from '@/components/scenarios/preview/AnswerImageColumn.vue'
import QuickReplyButton from './QuickReplyButton.vue'

@Options({
  components: { Imagemap, ImageCarouselColumn, AnswerImageColumn, QuickReplyButton },
  directives: { maska },
  emits: ['update:handleAnswerClick', 'update:scrollToBottom', 'nextCampaign'],
})
export default class ChatMessageSelector extends Vue {
  @Prop()
  storeMessages!: IChat[]

  @Prop()
  nextCards!: ICard[]

  nextUniqueId = ''
  lastCard = {}

  formFields: IFormDetail[] = []
  fieldIndex = 0

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get messages(): IMessage[] {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  get avatarUrl() {
    return 'https://ui-avatars.com/api/?name=' + this.userInfo.display_name + '&background=ff9800&size=40&length=1'
  }

  get noAvatarUrl() {
    return window.location.origin + '/img/default-user-avatar.png'
  }

  handleCallback(callback: Function) {
    return new Promise(function (resolve) {
      setTimeout(() => {
        callback()
        resolve(true)
      }, 1000)
    })
  }

  addMsg(msg) {
    if (this.storeMessages) {
      this.storeMessages.push(msg)
    }
  }

  addCard(card) {
    if (card) {
      this.lastCard = card
      this.$emit('update:scrollToBottom')
    }
  }

  async renderMessage(messages) {
    let msg: IChat = { text: [''], sent: false, type: constant.ANSWER_TYPE.TEXT }
    if (messages) {
      for (const element of messages) {
        if (element.type === constant.ANSWER_TYPE.MESSAGE) {
          msg = {
            text: [element.message ?? ''],
            type: constant.ANSWER_TYPE.TEXT,
          }
          await this.handleCallback(() => {
            this.addMsg(msg)
          })
        } else if (element.type === constant.ANSWER_TYPE.IMAGEMAP) {
          msg = {
            type: constant.ANSWER_TYPE.IMAGEMAP,
            imagemap_answer: element.imagemap_answer,
          }
          await this.handleCallback(() => {
            this.addMsg(msg)
          })
        } else {
          msg = {
            type: constant.ANSWER_TYPE.IMAGECAROUSEL,
            imagecarousels: element.images,
          }
          await this.handleCallback(() => {
            this.addMsg(msg)
          })
        }
      }
    }
  }

  async renderAnswer(uniqueId, title, answers) {
    let msg: IChat = { text: [''], sent: false, type: constant.ANSWER_TYPE.TEXT }
    if (title !== '') {
      msg = {
        text: [title],
        type: constant.ANSWER_TYPE.TEXT,
      }
      await this.handleCallback(() => {
        this.addMsg(msg)
      })
    }
    if (answers && answers.type === constant.ANSWER_TYPE.IMAGEMAP) {
      msg = {
        type: constant.ANSWER_TYPE.IMAGEMAP,
        uniqueId: uniqueId,
        imagemap_answer: answers.imagemap_answer,
      }
      await this.handleCallback(() => {
        this.addMsg(msg)
      })
    } else if (answers && answers.type === constant.ANSWER_TYPE.IMAGE) {
      msg = {
        type: constant.ANSWER_TYPE.IMAGE,
        uniqueId: uniqueId,
        image_answers: answers.image_answers,
      }
      await this.handleCallback(() => {
        this.addMsg(msg)
      })
    }
  }

  async renderFormFields(fields) {
    this.formFields = fields
    this.fieldIndex = 0
    await this.renderField()
  }

  @Watch('fieldIndex')
  async renderField() {
    if (this.formFields.length > 0) {
      const field: IFormDetail = this.formFields[this.fieldIndex]
      let msg: IChat = { text: [''], sent: false, type: constant.ANSWER_TYPE.TEXT }
      if (field && field.field_name) {
        msg = {
          text: [field.field_name],
          type: constant.ANSWER_TYPE.TEXT,
        }
        await this.handleCallback(() => {
          this.addMsg(msg)
        })
      }
    }
  }

  @Watch('nextCards', { immediate: true })
  async renderCards() {
    this.lastCard = {}
    this.nextUniqueId = ''
    if (this.nextCards) {
      for (const i in this.nextCards) {
        const card: ICard = this.nextCards[i]
        this.nextUniqueId = card.uniqueId
        if (card.cardType === EScenarioResourceType.message) {
          const resource = this.messages.find((item) => item._id === card.id)
          if (resource) {
            await this.renderMessage(resource.messages)
          }
        } else if (card.cardType === EScenarioResourceType.question) {
          const resource: IQuestion = this.questions.find((item) => item._id === card.id)
          if (resource) {
            await this.renderAnswer(card.uniqueId, resource.message, resource.answers)
          }
        } else if (card.cardType === EScenarioResourceType.form) {
          const resource: IForm = this.forms.find((item) => item._id === card.id)
          if (resource) {
            await this.renderFormFields(resource.details)
          }
        } else {
          const resource: IGoal = this.goals.find((item) => item._id === card.id)
          if (resource) {
            await this.renderMessage(resource.details)
            await this.renderAnswer(card.uniqueId, resource.message, resource.answers)
          }
        }

        if (card.cardType !== EScenarioResourceType.message && card.answers && card.answers.length > 0) {
          await this.handleCallback(() => {
            this.addCard(card)
          })
        }
        if (card.cardType === EScenarioResourceType.message || card.cardType === EScenarioResourceType.goal) {
          this.checkLastCard(card)
        }
      }
    }
  }

  checkLastCard(card: ICard) {
    let hasNextCard = false
    const answers = card.answers
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].id !== 'any' || answers[i].nextCards.length) {
        hasNextCard = true
      }
    }
    if (!hasNextCard) {
      this.$emit('nextCampaign')
    }
  }

  async validateFreetextAnswer(answers: IAnswer, answerLabel: string) {
    let pattern
    if (answers) {
      const freetext_answer = answers.freetext_answer
      if (freetext_answer) {
        const option_type = freetext_answer?.option_type
        if (option_type === FREETEXT_OPTION_TYPE.PHONE_NUMBER_DASH) {
          pattern = '^\\d{2,3}\\-\\d{3,4}\\-\\d{4}$'
        } else if (option_type === FREETEXT_OPTION_TYPE.PHONE_NUMBER_NO_DASH) {
          pattern = '^\\d{2,3}\\d{8}$'
        } else if (option_type === FREETEXT_OPTION_TYPE.EMAIL) {
          pattern =
            "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
        } else if (option_type === FORM_FIELD_TYPE.NAME_KANJI) {
          pattern = '^[^ -~｡-ﾟ]+$'
        } else if (option_type === FORM_FIELD_TYPE.NAME_HIRA) {
          pattern = '^[ぁ-ゞ]+$'
        } else if (option_type === FORM_FIELD_TYPE.NAME_KANA) {
          pattern = '^[ァ-ヶ]+$'
        } else if (option_type === FORM_FIELD_TYPE.POSTCODE_DASH) {
          pattern = '^[0-9]{3}-[0-9]{4}$'
        } else if (option_type === FORM_FIELD_TYPE.POSTCODE_NO_DASH) {
          pattern = '^[0-9]{7}$'
        }
        if (pattern !== '') {
          const regexp = new RegExp(pattern)
          const result = regexp.test(answerLabel)
          if (!result) {
            let msg: IChat = {
              text: [answerLabel],
              name: 'me',
              sent: true,
              type: constant.ANSWER_TYPE.TEXT,
            }
            await this.handleCallback(() => {
              this.addMsg(msg)
            })

            msg = {
              text: [freetext_answer.error_message ?? ''],
              sent: false,
              type: constant.ANSWER_TYPE.TEXT,
            }
            await this.handleCallback(() => {
              this.addMsg(msg)
            })
            return false
          }
        }
      }
    }

    return true
  }

  getResourceByResourceType(resource_id, resourceType) {
    let item: IQuestion | IGoal = {}
    if (resourceType === EScenarioResourceType.question) {
      item = this.questions.find((question) => question._id === resource_id)
    } else if (resourceType === EScenarioResourceType.goal) {
      item = this.goals.find((goal) => goal._id === resource_id)
    }

    return item
  }

  async handleAnswerClick(uniqueId: string, answerId: string, title: string) {
    if (this.lastCard) {
      const resource = this.lastCard as ICard
      if (resource.cardType === 'form' && this.formFields.length > this.fieldIndex) {
        const field = this.formFields[this.fieldIndex]
        const answer: IAnswer = {
          freetext_answer: field,
        }
        let isValid = true
        if (answer) {
          isValid = await this.validateFreetextAnswer(answer, title)
        }

        if (isValid) {
          const msg: IChat = {
            text: [title],
            sent: true,
            type: constant.ANSWER_TYPE.TEXT,
          }
          await this.handleCallback(() => {
            this.addMsg(msg)
          })

          this.fieldIndex++

          if (this.fieldIndex > this.formFields.length - 1) {
            this.$emit('update:handleAnswerClick', uniqueId, answerId)
          }
        }
      } else {
        const card = this.getResourceByResourceType(resource.id, resource.cardType)
        let isValid = true
        if (card && card.answers && card.answers.type === constant.ANSWER_TYPE.FREETEXT) {
          isValid = await this.validateFreetextAnswer(card.answers, title)
        }

        if (isValid) {
          const msg: IChat = {
            text: [title],
            sent: true,
            type: constant.ANSWER_TYPE.TEXT,
          }
          await this.handleCallback(() => {
            this.addMsg(msg)
          })
          this.$emit('update:handleAnswerClick', uniqueId, answerId)
        }
      }
    }
  }

  backField() {
    if (this.fieldIndex > 0) {
      this.fieldIndex--
    }
  }
}
