import { IUploadFileMultiSizesResponse, IUploadFileResponse } from '@/utils/types'
import { NormApi } from './norm'

export class UploadApi extends NormApi<IUploadFileResponse> {
  async uploadFile(file: File): Promise<IUploadFileResponse> {
    // Create new form data to upload
    const data = new FormData()
    data.append('file', file)
    const response = await this.axios.post('/v1/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return this.responseHandler(response)
  }

  async uploadFileMultiWidth(
    file: File,
    is_apply_all_device: boolean,
    folder_id: string,
    width: number
  ): Promise<IUploadFileMultiSizesResponse> {
    // Create new form data to upload
    const data = new FormData()
    data.append('file', file)
    data.append('is_apply_all_device', is_apply_all_device.toString())
    data.append('folder_id', folder_id)
    data.append('width', width.toString())
    const response = await this.axios.post('/v1/upload-multi-sizes', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return this.responseHandler(response)
  }
}

export default new UploadApi()
