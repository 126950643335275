<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <TestModeSelector v-model="isTestMode" @update:detailCopy="onDetailCopy" v-if="form._id !== ''" />
            <label class="text-grey-9 text-bold">{{ $t('label.campaign.campaign_name') }}</label>
            <q-input
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || requiredRule.campaign_name]"
              outlined
              v-model="form.campaign_name"
              dense
              :placeholder="$t('label.campaign.campaign_name')"
            >
            </q-input>
            <TriggerSettingSelector
              v-if="isTestMode"
              v-model="form.test_detail.events_setting"
            ></TriggerSettingSelector>
            <TriggerSettingSelector v-else v-model="form.prod_detail.events_setting"></TriggerSettingSelector>
          </div>

          <q-space class="q-my-md" />
          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ICampaign } from '@/utils/types'
import { ACTION_CAMPAIGN } from '@/store/actions'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import TriggerSettingSelector from './selectors/TriggerSettingSelector.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { TriggerSettingSelector, TestModeSelector },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class CampaignFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: ICampaign

  isTestMode = false
  form: ICampaign = {
    _id: '',
    app_id: '',
    is_active: false,
    is_test_mode: false,
    is_traffic_source: false,
    campaign_name: '',
    prod_detail: {
      events_setting: {
        back_button_click: {
          is_active: true,
          force_display: true,
          seconds: 0,
        },
        mouse_out_screen: {
          is_active: true,
          seconds: 2,
        },
        after_page_loaded: {
          is_active: true,
          seconds: 50,
        },
        scroll_up: {
          is_active: true,
          position: 1,
        },
        scroll_down: {
          is_active: true,
          position: 1,
        },
        number_display_trigger: {
          is_active: true,
          number: 1,
        },
      },
    },
    test_detail: {
      events_setting: {
        back_button_click: {
          is_active: true,
          force_display: true,
          seconds: 0,
        },
        mouse_out_screen: {
          is_active: true,
          seconds: 2,
        },
        after_page_loaded: {
          is_active: true,
          seconds: 50,
        },
        scroll_up: {
          is_active: true,
          position: 1,
        },
        scroll_down: {
          is_active: true,
          position: 1,
        },
        number_display_trigger: {
          is_active: true,
          number: 1,
        },
      },
    },
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get requiredRule() {
    const requiredRule = {
      campaign_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.campaign.campaign_name'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.campaign.add_new_campaign') : this.$t('label.campaign.edit_campaign')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let item = null
    if (this.isNew) {
      this.form.app_id = this.selectedAppId
      this.form.test_detail = this.form.prod_detail
      item = await dispatch(ACTION_CAMPAIGN.ADD, {
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_CAMPAIGN.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }
    console.log(item)
    if (item) {
      this.$emit('update:onSelect', item)

      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  onDetailCopy() {
    if (this.isTestMode) {
      this.form.test_detail = this.form.prod_detail
    } else {
      this.form.prod_detail = this.form.test_detail
    }
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }
}
</script>
