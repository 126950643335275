<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm">プレビュー</q-toolbar-title>
        <div class="row no-wrap q-gutter-sm">
          <q-btn flat round dense icon="replay" @click="resetPreview" />
          <q-btn flat round dense icon="close" v-close-popup />
        </div>
      </q-toolbar>
      <q-card-section style="height: 90vh; display: flex;">
        <div class="preview-device--sp row justify-center">
          <div class="preview-device--container" ref="pageChat">
            <div class="q-pa-sm column col justify-end preview-device--messages-list">
              <ChatMessageSelector
                :storeMessages="storeMessages"
                :nextCards="nextCards"
                @update:handleAnswerClick="handleScenario"
                @update:scrollToBottom="scrollToBottom"
                @nextCampaign="$emit('nextCampaign')"
              ></ChatMessageSelector>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ICard, ICardAnswer } from 'bot-flow-maker/src/types'
import ChatMessageSelector from '@/components/scenarios/preview/ChatMessageSelector.vue'
import { EScenarioResourceType, IChat } from '@/utils/types'

@Options({
  components: { ChatMessageSelector },
  directives: { maska },
  emits: ['update:closeModal', 'nextCampaign'],
})
export default class PreviewSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  scenarios!: ICard[]

  storeMessages: IChat[] = []
  nextCards: ICard[] = []

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  getNextCards(uniqueId: string, answerId: string) {
    const nextCards: ICard[] = []
    let nextCard: ICard = this.getNextCardFromScenarios(uniqueId, answerId)

    if (nextCard) {
      nextCards.push(nextCard)
      if (nextCard.cardType !== EScenarioResourceType.question && nextCard.cardType !== EScenarioResourceType.form) {
        while (true) {
          nextCard = this.getNextCardFromScenarios(nextCard?.uniqueId ?? '', '')
          if (nextCard) {
            nextCards.push(nextCard)
            if (nextCard.cardType !== EScenarioResourceType.message) {
              break
            }
          } else {
            break
          }
        }
      }
    }

    return nextCards
  }

  getNextCardFromScenarios(uniqueId: string, answerId: string) {
    let next
    if (this.scenarios) {
      for (const key in this.scenarios) {
        const scenario = this.scenarios[key]
        const currentUniqueId = scenario.uniqueId
        if (uniqueId === '') {
          if (this.isRootResource(currentUniqueId)) {
            next = scenario
            break
          }
        } else {
          if (currentUniqueId === uniqueId) {
            const answers = scenario.answers
            const nextUniqueId = this.getNextUniqueIdByAnswerId(answers, answerId)
            if (nextUniqueId) {
              const nextScenario = this.getScenarioByUniqueId(nextUniqueId)
              if (nextScenario) {
                next = nextScenario
                break
              }
            }
          }
        }
      }
    }

    return next
  }

  isRootResource(uniqueId: string) {
    if (this.scenarios) {
      for (const i in this.scenarios) {
        const scenario = this.scenarios[i]
        const answers = scenario.answers
        for (const j in answers) {
          const answer = answers[j]
          const nextCards = answer.nextCards
          for (const k in nextCards) {
            const next = nextCards[k]
            if (next.uniqueId === uniqueId) {
              return false
            }
          }
        }
      }
    }
    return true
  }

  getNextUniqueIdByAnswerId(answers: ICardAnswer[], answerId: string) {
    for (const i in answers) {
      const answer = answers[i]
      if (answer.id === answerId || answer.id === 'any') {
        const nextCards = answer.nextCards
        for (const k in nextCards) {
          const next = nextCards[k]
          return next.uniqueId
        }
      }
    }
    return ''
  }

  getScenarioByUniqueId(uniqueId: string) {
    for (const i in this.scenarios) {
      const scenario = this.scenarios[i]
      if (scenario.uniqueId === uniqueId) {
        return scenario
      }
    }
    return undefined
  }

  handleScenario(uniqueId: string, answerId: string) {
    this.nextCards = this.getNextCards(uniqueId, answerId)
    if (!this.nextCards.length) {
      this.$emit('nextCampaign')
    }
  }

  resetPreview() {
    this.storeMessages = []
    this.initialScenarios()
  }

  @Watch('storeMessages', { deep: true })
  scrollToBottom() {
    if (this.storeMessages.length > 0) {
      const pageChat = this.$refs.pageChat
      if (pageChat) {
        this.$nextTick(() => {
          // DOM updated
          pageChat.scrollTop = pageChat.scrollHeight
        })
      }
    }
  }

  @Watch('scenarios', { immediate: true })
  initialScenarios() {
    const startCard = this.scenarios.find(item => item.id === EScenarioResourceType.start)
    if (startCard) {
      this.handleScenario(startCard.uniqueId, '')
    } else {
      this.handleScenario('', '')
    }
  }

  async created() {
    this.initialScenarios()
  }
}
</script>

<style lang="scss" scoped>
.preview-device {
  position: relative;
  $root: &;
  &--sp {
    overflow: hidden;
    position: relative;
    background: url('/img/devices/iphone_14.png') no-repeat center;
    background-size: contain;
    margin: auto;
    height: 80%;
    aspect-ratio: 421 / 850;
    top: 8px;
    #{$root} {
      &--container {
        border: 24px solid transparent;
        border-top-width: 50px;
        border-bottom-width: 40px;
      }
    }

    .preview-device--popup--overlay {
      width: 100%;
    }
  }
  &--container {
    margin: 0 auto;
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
    }
  }
  &--messages-list {
    min-height: 100%;
    width: 100%;
    background: white;
    z-index: 1;
    position: absolute;
    top: 0;
  }
}

.q-message {
  z-index: 1;
}
.q-banner {
  top: 50px;
  z-index: 2;
  opacity: 0.7;
}
</style>
