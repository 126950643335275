
import { maska } from 'maska'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IEventSetting } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class TriggerSettingSelector extends Vue {
  @Prop({})
  modelValue!: IEventSetting

  @Prop({ default: true })
  inModal!: boolean

  get requiredRule() {
    const requiredRule = {
      number_display_trigger: this.$t('messages.number_display_trigger_must_be_between_1_and_10'),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: IEventSetting) {
    this.$emit('update:modelValue', value)
  }
}
