
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import TagCodeSingle from '@/components/common/ui/TagCodeSingle.vue'
import TagCode from '@/components/common/ui/TagCode.vue'

@Options({
  components: { TagCodeSingle, TagCode },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class TagCodeModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  trackingCode!: string

  @Prop()
  campaignTagJsUrl!: string

  @Prop()
  successTagJsUrl!: string

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  onCancel() {
    this.visible = false
  }
}
