<template>
  <q-toolbar>
    <q-form @submit="onSendMessage" class="full-width">
      <q-input
        ref="newMessage"
        bg-color="white"
        rounded
        v-model="form.newMessage"
        placeholder="Message"
        class="full-width"
        dense
        outlined
      >
        <template>
          <q-btn round dense flat type="submit" icon="send" color="primary" />
        </template>
      </q-input>
    </q-form>
  </q-toolbar>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IChat, IFormDetail } from '@/utils/types'
import { constant } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:handleFreeTextAnswer'],
})
export default class SendMessageSelector extends Vue {
  @Prop()
  modelValue!: IChat[]

  @Prop()
  answerType!: string

  @Prop()
  nextUniqueId!: string

  @Prop()
  formField!: IFormDetail

  form = {
    newMessage: '',
  }

  get value() {
    return this.modelValue
  }

  set value(value: IChat[]) {
    this.$emit('update:modelValue', value)
  }

  onSendMessage() {
    if (this.answerType === constant.ANSWER_TYPE.FREETEXT) {
      this.$emit('update:handleFreeTextAnswer', this.nextUniqueId, 'any', this.form.newMessage)
    } else {
      if (this.value) {
        const msg: IChat = {
          text: [this.form.newMessage],
          name: 'me',
          sent: true,
          type: constant.ANSWER_TYPE.TEXT,
        }
        this.value.push(msg)
      }
    }

    this.clearMessage()
  }

  clearMessage() {
    this.form.newMessage = ''
    this.$refs.newMessage.focus()
  }
}
</script>

<style lang="scss" scoped>
</style>
