
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { IMedia } from '@/utils/types'
import UploadApi from '@/api/upload'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import { constant } from '@/utils/constants'

@Options({
  components: { UploadingProgress, CropperImageSelector },
  directives: { maska },
  emits: ['onUploadedFile'],
})
export default class UploaderComponent extends Vue {
  @Prop()
  isCropImage!: boolean

  @Prop({ default: '.png, .jpeg, .jpg, image/*' })
  acceptFileTypes!: string

  @Prop({ default: '1:1' })
  cropRatio!: string

  @Prop({ default: 1 })
  aspectRatio!: number

  cropFiles: File[] = []
  file: File | null = null
  cropperImageModalVisible = false
  uploadedFiles: IMedia[] = []
  currentImageWidth = 1
  currentImageHeight = 1

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateImageWidth(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: this.cropRatio,
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
            this.resetUploader()
          })
      } else {
        this.cropFiles.push(this.file)
        this.addCroppedFiles(this.cropFiles)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  get appId() {
    return this.$route.params.app_id
  }

  async factoryFn(files) {
    return this.submitFile(files[0])
  }

  onAddFiles(files) {
    this.file = files[0]
  }

  onFactoryFail(err, files) {
    console.log('error: ', err)
    console.log('factoryFail files:', files)
  }

  addCroppedFiles(files) {
    this.$refs.cusUploader.reset()
    this.$refs.cusUploader.addFiles(files)
  }

  resetUploader() {
    this.$refs.cusUploader.reset()
  }

  async submitFile(file: File) {
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded) {
        const item: IMedia = {}
        item.app_id = this.appId
        item.name = uploaded.filename
        item.url = uploaded.file_url_org
        item.thumb = uploaded.file_url_thumb
        item.file_type = uploaded.extension
        item.image_width = this.currentImageWidth
        item.image_height = this.currentImageHeight
        item.content_type = file.type
        this.uploadedFiles.push(item)

        return {
          url: uploaded.file_url_org,
          method: 'GET',
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async validateImageWidth(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement

        if (this.aspectRatio === 1) {
          if (data.width !== data.height) {
            resolve(false)
          }

          resolve(true)
        } else {
          if (data.width / data.height < constant.RICH_MENU_IMAGE.MIN_ASPECT_RATIO) {
            this.$q.notify({
              type: 'negative',
              message: this.$t('validate.image_aspect_ratio', {
                placeholder: file.name,
              }),
            })
            resolve(false)
          }

          if (
            data.width < constant.RICH_MENU_IMAGE.MIN_IMAGE_WIDTH ||
            data.width > constant.RICH_MENU_IMAGE.MAX_IMAGE_WIDTH
          ) {
            this.$q.notify({
              type: 'negative',
              message: this.$t('validate.image_width_size', {
                placeholder: file.name,
              }),
            })
            resolve(false)
          }

          if (data.height < constant.RICH_MENU_IMAGE.MIN_IMAGE_HEIGHT) {
            this.$q.notify({
              type: 'negative',
              message: this.$t('validate.image_height_size', {
                placeholder: file.name,
              }),
            })
            resolve(false)
          }

          this.currentImageWidth = data.width
          this.currentImageHeight = data.height
          resolve(true)
        }
      }
    })
  }

  onRejected(rejectedEntries) {
    rejectedEntries.forEach((item) => {
      if (item.failedPropValidation === 'accept') {
        this.$q.notify({
          type: 'negative',
          message: this.$t('validate.file_not_accepted', {
            placeholder: item.file.name,
            extension: this.acceptFileTypes,
          }),
        })
      }
      if (item.failedPropValidation === 'max-file-size') {
        this.$q.notify({
          type: 'negative',
          message: this.$t('validate.file_too_big', {
            placeholder: item.file.name,
          }),
        })
      }
    })
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
  }

  async onCropImage(file, width, height) {
    this.currentImageWidth = width
    this.currentImageHeight = height
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    this.addCroppedFiles(this.cropFiles)
    this.onCloseModal()
  }

  uploadFiles() {
    this.$refs.cusUploader.upload()
  }

  onUploaded() {
    this.$emit('onUploadedFile', this.uploadedFiles)
    this.uploadedFiles = []
  }
}
