<template>
  <VuemojiPicker v-if="visible" @emojiClick="handleEmojiClick" />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { VuemojiPicker, EmojiClickEventDetail } from 'vuemoji-picker'

@Options({
  components: { VuemojiPicker },
})
export default class EmojiSelector extends Vue {
  @Prop()
  modelValue!: boolean

  get visible() {
    return this.modelValue
  }

  set visible(value) {
    this.$emit('update:modelValue', value)
  }

  handleEmojiClick(detail: EmojiClickEventDetail) {
    this.$emit('update:emoji', detail?.unicode || '')
  }

  onClickOutside(event: Event) {
    const tagName = (event.target as HTMLElement).tagName
    const isClose = !/emoji|^(i|span)$/i.test(tagName)
    if (this.visible && isClose) {
      this.visible = false
    }
  }

  mounted() {
    document.addEventListener('click', this.onClickOutside)
  }

  beforeDestroy() {
    document.removeEventListener('click', this.onClickOutside)
  }
}
</script>
