
import { mixins, Options } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_MEDIA } from '@/store/actions'
import { IMedia } from '@/utils/types'
import MediaDetails from '@/components/media-manager/MediaDetails.vue'
import UploaderComponent from '@/components/media-manager/UploaderComponent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
@Options({
  components: {
    UploaderComponent,
    MediaDetails,
    CLoading,
  },
  directives: { maska },
})
export default class MediaManager extends mixins(BaseFormMixin) {
  loading = true
  isAdd = false
  isSelectMedia = false
  searchName = ''
  currentPage = 1
  selectedMedia: IMedia = {}
  media: IMedia[] = []
  totalPages = 0
  perPage = 12
  perPageOptions = [12, 24, 36, 60]

  get appId() {
    return this.$route.params.app_id
  }

  get modalTitle() {
    return this.$t('media_upload')
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }
    this.loading = true
    await this.fetchMedia()
  }

  @Watch('currentPage')
  async currentPageChanged() {
    this.loading = true
    await this.fetchMedia()
  }

  @Watch('perPage')
  async currentPerPageChanged() {
    this.loading = true
    await this.fetchMedia()
  }

  async searchMedia() {
    this.loading = true
    await this.fetchMedia()
  }

  selectMedia(item) {
    this.isSelectMedia = true
    this.selectedMedia = item
  }

  async submitMedia() {
    this.$refs.uploaderComponent.uploadFiles()
  }

  async fetchMedia() {
    const result = await this.$store.dispatch(ACTION_MEDIA.LOAD_ITEMS, {
      app_id: this.appId,
      filter: {
        name: this.searchName,
        page: this.currentPage,
        per_page: this.perPage,
      },
    })
    this.media = result.items
    this.totalPages = result.total_pages

    this.loading = false
  }

  async onUploadedFiles(files) {
    const { dispatch } = this.$store
    if (files.length) {
      this.isAdd = false
      this.loading = true
      await dispatch(ACTION_MEDIA.ADD, files)
      await this.fetchMedia()
    }
  }

  onCancel() {
    this.isAdd = false
  }
}
