import { render } from "./QuickReplyButton.vue?vue&type=template&id=78718ff6&scoped=true"
import script from "./QuickReplyButton.vue?vue&type=script&lang=ts"
export * from "./QuickReplyButton.vue?vue&type=script&lang=ts"

import "./QuickReplyButton.vue?vue&type=style&index=0&id=78718ff6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-78718ff6"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QAvatar});
