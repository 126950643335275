
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

@Options({
  components: { Cropper },
  directives: { maska },
  emits: ['update:closeModal', 'update:onCropImage'],
})
export default class CropperImageSelector extends Vue {
  @Prop()
  file!: File

  @Prop()
  modalVisible!: boolean

  @Prop()
  aspectRatio!: number

  @Prop({ default: false })
  isStatic!: boolean

  @Prop({ default: 1040 })
  stencilWidth!: number

  @Prop({ default: 1040 })
  stencilHeight!: number

  get img() {
    return URL.createObjectURL(this.file)
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  change({ coordinates, canvas }) {
    console.log(coordinates, canvas)
  }

  defaultSize({ imageSize, visibleArea }) {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    }
  }

  onCancel() {
    this.visible = false
  }

  onSubmit() {
    const { coordinates, canvas } = this.$refs.cropper.getResult()
    console.log(coordinates, canvas)
    if (canvas) {
      if (this.isStatic) {
        const scaledCanvas: HTMLCanvasElement = document.createElement('canvas')
        scaledCanvas.width = this.stencilWidth
        scaledCanvas.height = this.stencilHeight
        const scaledContext: CanvasRenderingContext2D | null = scaledCanvas.getContext('2d')
        if (scaledContext) {
          scaledContext.drawImage(canvas, 0, 0, this.stencilWidth, this.stencilHeight)
          scaledCanvas.toBlob((blob: Blob | null) => {
            if (blob) {
              const newFile: File = new File([blob], this.file.name, { type: this.file.type })
              this.$emit('update:onCropImage', newFile, this.stencilWidth, this.stencilWidth)
            }
          }, this.file.type)
        }
      } else {
        canvas.toBlob((blob) => {
          const newFile = new File([blob], this.file.name, { type: this.file.type })
          this.$emit('update:onCropImage', newFile, coordinates.width, coordinates.height)
        }, this.file.type)
      }
    }
  }
}
