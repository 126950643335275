<template>
  <div class="tagcode" id="tabcode" @click="copy" v-if="trackingCode">
    <q-btn size="sm" v-if="trackingCode" @click="copy" class="">
      <q-icon left size="em" name="content_copy" />
      <span>{{ $t('label.copy') }}</span>
      <span>{{ $t('label.cv_tag') }}</span>
    </q-btn>

    <span class="q-mt-sm q-ml-sm has-text-grey">
      {{ $t('label.tab_location_desc') }}
    </span>
    <div class="is-clearfix"></div>

    <pre v-highlightjs="tagCode" class="q-mt-none"><code class="javascript"></code></pre>

    <q-btn size="sm" @click="downloadTagCode" class="button csv-position">
      <q-icon left size="em" name="download" />
      <span>
        {{ $t('label.ext_txt_file') }}
      </span>
    </q-btn>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class TagCodeSingle extends Vue {
  // Props
  @Prop()
  trackingCode!: string

  @Prop()
  tagJsUrl!: string

  get tagCode(): string {
    /* eslint-disable no-useless-escape */
    return `
    <script async type="text/javascript" src="https://${this.tagJsUrl}"><\/script>
    `
  }

  tagCopySuccess(): void {
    this.$q.notify({
      message: this.$t('label.copied'),
      color: 'positive',
    })
  }

  downloadTagCode() {
    const html = this.$t('label.comment_txt_file_download_tag') + this.tagCode
    const blob = new Blob([html], { type: 'text/plain' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'CV tag.txt'
    link.click()
  }

  copy(): void {
    const myFluffyTextarea = document.createElement('textarea')

    myFluffyTextarea.innerHTML = this.tagCode

    const parentElement = document.getElementById('tabcode')
    if (!parentElement) {
      return
    }

    parentElement.appendChild(myFluffyTextarea)
    myFluffyTextarea.select()

    document.execCommand('copy')

    parentElement.removeChild(myFluffyTextarea)
    this.tagCopySuccess()
  }
}
</script>

<style lang="sass" scoped>
.csv-position
  margin-top: -40px
  float: right
  margin-right: 2px
</style>
