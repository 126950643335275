import { render } from "./ButtonEmojiSelector.vue?vue&type=template&id=68e2425c"
import script from "./ButtonEmojiSelector.vue?vue&type=script&lang=ts"
export * from "./ButtonEmojiSelector.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMenu});
