<template>
  <div class="images q-mb-sm" :class="listImagesId">
    <img
      v-for="(image, i) in images"
      :src="image.image_url"
      style="height: 150px; max-width: 150px"
      :class="image.click_url !== '' ? 'cursor-pointer' : ''"
      @click="handleClickImage(image.click_url)"
      :key="i"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { DetailImageType } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class ImageCarouselColumn extends Vue {
  @Prop()
  images!: DetailImageType[]

  listImagesId = 'list-x-' + Math.floor(Math.random() * 100)

  handleClickImage(url) {
    if (url !== '') {
      window.open(url)
    }
  }

  mounted() {
    const slider = document.querySelector('.' + this.listImagesId) as HTMLElement
    let isDown = false
    let startX
    let scrollLeft
    if (slider) {
      slider.addEventListener('mousedown', (e) => {
        isDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3
        slider.scrollLeft = scrollLeft - walk
        console.log(walk)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

.images {
  max-width: 280px;
  // overflow-x: auto;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  will-change: transform;
  transition: all 0.2s;
}
</style>
