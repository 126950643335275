import { render } from "./SendMessageSelector.vue?vue&type=template&id=4db73378&scoped=true"
import script from "./SendMessageSelector.vue?vue&type=script&lang=ts"
export * from "./SendMessageSelector.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-4db73378"

export default script
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QForm,QInput,QBtn});
