
import { formatBytes } from '@/utils/helpers'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class UploadingProgress extends Vue {
  @Prop({ default: [] })
  files!: File[]

  formatBytes = formatBytes
}
