<template>
  <q-card :style="isReviewImage ? 'min-width: 40vw' : 'min-width: 20vw'">
    <q-img :src="selectedMedia.url" style="max-height: 800px" />
    <q-card-actions align="right">
      <q-btn flat round color="primary" icon="share" @click="copyToClipBoard(selectedMedia.url)" />
      <q-btn flat round color="teal" icon="download" @click="onDownload(selectedMedia.url, selectedMedia.name)" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IMedia } from '@/utils/types'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class MediaDetails extends Vue {
  @Prop()
  selectedMedia: IMedia = {}

  @Prop()
  isReviewImage!: boolean

  copyToClipBoard(textToCopy) {
    navigator.clipboard.writeText(textToCopy)
  }

  async onDownload(url, name) {
    // const baseUrl = process.env.VUE_APP_API_ENDPOINT
    // const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(url)
    const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = name
    link.click()
  }
}
</script>

<style scoped lang="scss">
:deep(.q-img__image) {
  width: auto !important;
}
:deep(.q-img__container.absolute-full) {
  text-align: center;
}
</style>
