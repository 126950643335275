<template>
  <div class="area-item" @click="handleAnswerClick()" :class="isClick ? 'cursor-pointer' : ''">
    <div
      class="target"
      :style="{
        left: data.x + 'px',
        top: data.y + 'px',
        width: data.width + 'px',
        height: data.height + 'px',
      }"
    >
      <span class="area-item__area-shape-frame__numbering"> {{ index + 1 }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ITappableArea } from '@/utils/types'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:handleAnswerClick'],
})
export default class TappableItem extends Vue {
  @Prop()
  data!: ITappableArea

  @Prop()
  index!: number

  get isClick() {
    if (
      (this.data && this.data.action_type === IMAGEMAP_MESSAGE.ACTION_TYPE.URI && this.data.link_uri !== '') ||
      (this.data && this.data.action_type === IMAGEMAP_MESSAGE.ACTION_TYPE.MESSAGE)
    ) {
      return true
    }
    return false
  }

  handleAnswerClick() {
    if (this.data && this.data.action_type === IMAGEMAP_MESSAGE.ACTION_TYPE.URI && this.data.link_uri !== '') {
      window.open(this.data.link_uri)
    } else {
      this.$emit('update:handleAnswerClick', this.data._id ?? '', this.data.title)
    }
  }
}
</script>
<style scoped>
.target {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 2px dashed #44aaff;
  white-space: nowrap;
}
</style>
