import { render } from "./TagCode.vue?vue&type=template&id=379bf9b1&scoped=true"
import script from "./TagCode.vue?vue&type=script&lang=ts"
export * from "./TagCode.vue?vue&type=script&lang=ts"

import "./TagCode.vue?vue&type=style&index=0&id=379bf9b1&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-379bf9b1"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
