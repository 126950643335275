<template>
  <q-btn
    round
    color="white"
    text-color="black"
    push
    size="sm"
    icon="emoji_emotions"
    @click="isShowEmojiList = !isShowEmojiList"
  >
    <q-menu>
      <EmojiSelector v-model="isShowEmojiList" @update:emoji="handelChange($event)" />
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import EmojiSelector from './EmojiSelector.vue'
@Options({
  components: {
    EmojiSelector,
  },
  directives: { maska },
})
export default class ButtonEmojiSelector extends Vue {
  @Prop()
  modelValue!: string

  @Prop()
  maxlength!: number

  isShowEmojiList = false

  pointerPosition = 0

  get value() {
    return this.modelValue
  }

  set value(value) {
    this.$emit('update:modelValue', value)
  }

  handelChange(val: string) {
    const myField = this.getInputField()
    if (!myField) {
      this.value += val
      return
    }

    let startPos = (myField as HTMLInputElement).selectionStart || null
    let endPos = (myField as HTMLInputElement).selectionEnd || null

    if (startPos === null || endPos === null) {
      // Current pointer > 0 then set new position, else return value
      if (this.pointerPosition > 0) {
        startPos = endPos = this.pointerPosition
      } else {
        this.value += val
        return
      }
    }

    this.pointerPosition = startPos + val.length
    this.value = this.value.substring(0, startPos) + val + this.value.substring(endPos, this.value.length)
  }

  getInputField() {
    const textFieldEl = document.getElementById('emoji-btn')?.parentNode
    if (!textFieldEl) return null

    const previousSibling = textFieldEl.previousSibling?.firstChild
    if (!previousSibling) return null

    return previousSibling
  }

  updated() {
    const myField = this.getInputField()
    if (!myField || !(myField as HTMLInputElement).setSelectionRange) return // Set new index for pointer
    ;(myField as HTMLInputElement).setSelectionRange(this.pointerPosition, this.pointerPosition)
  }
}
</script>
