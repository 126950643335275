<template>
  <div>
    <label class="text-bold text-grey-9" v-if="inModal">{{ $t('label.campaign.trigger') }}</label>
    <div>
      <q-checkbox v-model="value.back_button_click.is_active" :label="$t('label.campaign.back_button_click')" />
      <!-- <q-checkbox
        v-model="value.back_button_click.force_display"
        :disable="!value.back_button_click.is_active"
        :label="$t('label.campaign.force_display')"
      /> -->
    </div>

    <div class="row">
      <q-checkbox
        v-model="value.mouse_out_screen.is_active"
        :label="$t('label.campaign.mouse_out_screen')"
        class="q-pr-sm"
      />
      <q-input
        lazy-rules
        outlined
        :disable="!value.mouse_out_screen.is_active"
        v-model="value.mouse_out_screen.seconds"
        type="number"
        min="0"
        dense
        style="max-width: 100px"
      >
        <template v-slot:after>
          <div class="sub-title">{{ $t('label.campaign.seconds') }}</div>
        </template>
      </q-input>
    </div>

    <div class="row">
      <q-checkbox
        v-model="value.after_page_loaded.is_active"
        :label="$t('label.campaign.page_load_finished_after_seconds')"
        class="q-pr-sm"
      />
      <q-input
        lazy-rules
        outlined
        v-model="value.after_page_loaded.seconds"
        :disable="!value.after_page_loaded.is_active"
        type="number"
        min="0"
        dense
        style="max-width: 215px"
      >
        <template v-slot:after>
          <div class="sub-title">
            {{ $t('label.campaign.page_load_finished_after_seconds2') }}
          </div>
        </template>
      </q-input>
    </div>
    <div class="row">
      <q-checkbox v-model="value.scroll_up.is_active" :label="$t('label.campaign.scroll_up')" class="q-pr-sm" />
      <q-input
        lazy-rules
        outlined
        v-model="value.scroll_up.position"
        :disable="!value.scroll_up.is_active"
        type="number"
        min="0"
        dense
        style="max-width: 100px"
      >
        <template v-slot:after><div class="sub-title">%</div> </template>
      </q-input>
    </div>
    <div class="row">
      <q-checkbox v-model="value.scroll_down.is_active" :label="$t('label.campaign.scroll_down')" class="q-pr-sm" />
      <q-input
        lazy-rules
        outlined
        v-model="value.scroll_down.position"
        :disable="!value.scroll_down.is_active"
        type="number"
        min="0"
        dense
        style="max-width: 100px"
      >
        <template v-slot:after><div class="sub-title">%</div> </template>
      </q-input>
    </div>
    <div class="row">
      <q-checkbox
        v-model="value.number_display_trigger.is_active"
        :label="$t('label.campaign.number_display_trigger')"
        class="q-pr-sm"
      />
      <q-input
        lazy-rules
        outlined
        v-model="value.number_display_trigger.number"
        :disable="!value.number_display_trigger.is_active"
        type="number"
        min="1"
        max="10"
        dense
        style="padding-left: 7px; width: 90px"
        :rules="[(val) => (val && val > 0 && val < 10) || requiredRule.number_display_trigger]"
      >
      </q-input>
    </div>
  </div>
</template>

<script lang="ts">
import { maska } from 'maska'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IEventSetting } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class TriggerSettingSelector extends Vue {
  @Prop({})
  modelValue!: IEventSetting

  @Prop({ default: true })
  inModal!: boolean

  get requiredRule() {
    const requiredRule = {
      number_display_trigger: this.$t('messages.number_display_trigger_must_be_between_1_and_10'),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: IEventSetting) {
    this.$emit('update:modelValue', value)
  }
}
</script>
<style scoped lang="scss">
:deep(.q-field__control),
:deep(.q-field__append) {
  height: 32px;
}

.sub-title {
  font-size: 13px;
  color: #212529;
  // font-weight: 400;
  // line-height: 1.25rem;
  // letter-spacing: 0.01786em;
}
</style>
