import { render } from "./UploaderComponent.vue?vue&type=template&id=2290c973"
import script from "./UploaderComponent.vue?vue&type=script&lang=ts"
export * from "./UploaderComponent.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QUploader,QList,QItem,QItemSection,QItemLabel,QBtn});
