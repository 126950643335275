<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    title="Treats"
    ref="welcomeList"
    v-if="stories"
    :rows="stories"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th key="action" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.prod') }}</span>
        </q-th>
        <q-th key="action" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.test') }}</span>
        </q-th>
        <q-th :props="props" key="trigger_type" style="width: 100px">
          <span class="text-bold">{{ $t('label.story.trigger') }}</span>
        </q-th>
        <q-th key="edit" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.edit') }}</span>
        </q-th>
        <q-th key="preview" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.preview') }}</span>
        </q-th>
        <q-th :props="props" key="story_name">
          <span class="text-bold">{{ $t('label.story.story_name') }}</span>
        </q-th>
        <q-th :props="props" key="created_at">
          <span class="text-bold">{{ $t('label.created_at') }}</span>
        </q-th>
        <q-th :props="props" key="creator_name">
          <span class="text-bold">{{ $t('label.story.creator_name') }}</span>
        </q-th>
        <q-th :props="props" key="updated_at">
          <span class="text-bold">{{ $t('label.updated_at') }}</span>
        </q-th>
        <q-th :props="props" key="updater_name">
          <span class="text-bold">{{ $t('label.story.updater_name') }}</span>
        </q-th>
        <q-th key="edit" class="text-center">
          <span class="text-bold">{{ $t('delete') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td>
        <q-td key="trigger_type" :props="props">
          <q-chip :class="'story-type-' + props.row.trigger_type">
            {{ $t('label.story.' + props.row.trigger_type + '_trigger') }}
          </q-chip>
        </q-td>
        <q-td class="text-center">
          <!-- <q-btn size="sm" round outline icon="edit" @click="onEdit(props.row)" /> -->
          <q-btn size="sm" flat round icon="edit" @click="onEdit(props.row)" />
        </q-td>
        <q-td class="text-center">
          <q-btn size="sm" flat round icon="visibility" @click="onPreview(props.row)" />
        </q-td>
        <q-td key="story_name" :props="props">
          <span>{{ props.row.story_name }}</span>
        </q-td>
        <q-td key="created_at" :props="props">
          <span>{{ props.row.created_at }}</span>
        </q-td>
        <q-td key="creator_name" :props="props">
          <span>{{ props.row.creator_name }}</span>
        </q-td>
        <q-td key="created_at" :props="props">
          <span>{{ props.row.updated_at }}</span>
        </q-td>
        <q-td key="updater_name" :props="props">
          <span>{{ props.row.updater_name }}</span>
        </q-td>
        <q-td class="text-center">
          <q-btn size="sm" color="red" flat round icon="delete" @click="onDelete(props.row)" />
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.story.story_list') }}</h2>
      <q-space />
      <q-btn no-caps color="primary" @click="onAdd" :label="$t('label.story.add_new_story')" />
    </template>
  </q-table>
  <PreviewSelectorModal
    v-if="modalPreviewVisible"
    :modalVisible="modalPreviewVisible"
    :scenarios="campaignDetails[campaignIndex].scenarios"
    @update:closeModal="onClosePreviewModal"
    @nextCampaign="onNextCampaign"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IInitialCampaign, IInitialCampaignDetail, IPushCampaign, IPushCampaignDetail, IStory } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import {
  ACTION_GOAL,
  ACTION_INITIAL,
  ACTION_MESSAGES,
  ACTION_PUSH,
  ACTION_QUESTION,
  ACTION_STORY,
} from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { constant, TRIGGER_TYPE } from '@/utils/constants'
import PreviewSelectorModal from '@/components/scenarios/selectors/PreviewSelectorModal.vue'

@Options({
  components: { PreviewSelectorModal, CLoading },
  directives: { maska },
  emits: [],
})
export default class StoryList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  modalPreviewVisible = false
  campaignIndex = 0
  campaignDetails: IInitialCampaignDetail[] | IPushCampaignDetail[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async updateIsActive(item: IStory) {
    const isSuccess = await this.$store.dispatch(ACTION_STORY.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IStory) {
    if (!record.is_active) {
      await this.updateIsActive(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.updateIsActive(record)
        })
        .onCancel(async () => {
          record.is_active = false
        })
    }
  }

  async handleToggleTest(record: IStory) {
    if (!record.is_test_mode) {
      await this.updateIsTest(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.updateIsTest(record)
        })
        .onCancel(async () => {
          record.is_test_mode = false
        })
    }
  }

  async updateIsTest(item: IStory) {
    const isSuccess = await this.$store.dispatch(ACTION_STORY.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  onAdd() {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      action_type: constant.ACTION_TYPE.ADD,
    })
  }

  onEdit(data: IStory) {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      story_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  async onPreview(story: IStory) {
    if (story.trigger_type === TRIGGER_TYPE.POPUP_DELIVER) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, story._id)
      if (initial._id !== '' && initial.prod_detail) {
        this.campaignDetails = [...this.campaignDetails, initial.prod_detail]
      }
      const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, story._id)
      for (const push of pushes) {
        if (push.prod_detail) {
          this.campaignDetails = [...this.campaignDetails, push.prod_detail]
        }
      }
    }
    if (this.campaignDetails.length) {
      this.modalPreviewVisible = true
    }
  }

  onClosePreviewModal() {
    this.campaignDetails = []
    this.campaignIndex = 0
    this.modalPreviewVisible = false
  }

  onNextCampaign() {
    if (this.campaignIndex < this.campaignDetails.length - 1) {
      this.campaignIndex++
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async onDelete(data: IStory) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_STORY.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }
}
</script>
<style scoped lang="scss">
.story-type-popup_deliver {
  background: #3982c2;
  color: white;
}
.story-type-audience {
  background: #f42b73;
  color: white;
}
.story-type-all {
  background: #9051b9;
  color: white;
}
</style>
